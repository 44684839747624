@import 'variable.scss';
@import 'mixins.scss';

.offerWrap {
    @include displayFlex;
    flex-wrap: wrap;
    padding: 50px 0;

    .offerleftlist {
        width: 45%;
        @media (max-width: 991px) {
            display: none;
        }

    .top-detail{margin-top: 150px;}

        
    .offerList {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                min-height: 63px;
                font-size: 20px;
                @include displayFlex;
                align-items: center;
                padding: 10px 0;

                i {
                    margin-right: 10px;
                    font-size: 24px;
                    color: #223bb4;
                }

                &:not(:last-child){
                    border-bottom: 1px dashed rgb(216, 216, 216);
                }
            }
        }
    }


    }

    .offerrightlist {
        width: 55%;
        @include displayFlex;
        @media (max-width: 991px) {
            width: 100%;
            flex-direction: column;
        }
    }

    .offerBlock {
        @include flex-1;
        padding: 24px 0px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 24px 0px;
        width: 200px;
        position: relative;
        @include borderRadius(16px 0 0 16px);
        text-align: center;
        @include displayFlex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 991px) {
            flex: none;
            width: 100%;
            text-align: left;
            align-items: start;

            .percentageBlockWrap {
                padding: 0px 30px;
            }
        }

        .percentageBlock {
            background: rgba(34, 34, 34, 0.03);
            padding: 3px 10px 13px;
            border-radius: 12px;
            width: max-content;
            @include displayFlex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          
            span{
                margin-top: -15px;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
        
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 63px;
                font-size: 18px;

                i{font-size: 24px;color: #b1b1b1;}

                @media (max-width: 991px) {
                    padding: 0 30px;
                    justify-content: start;

                    div{
                        align-items: center;
                        i{
                            font-size: 24px;
                            margin-right: 10px;
                            color: #223bb4;
                        }
                    }
                }

                &:not(:last-child){
                    border-bottom: 1px dashed rgb(216, 216, 216);
                }
            }
        }

        .priceBlock{
            @media (max-width: 991px) {
                padding: 0 30px;
            }
            .price {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 5px;
            }

            .btn {
                border: 1px solid $green-line;
                border-radius: 25px;
                height: 45px;
                padding: 0 25px;
                text-transform: uppercase;
                @include transition (all, 0.3s, ease-in-out);

                &:hover{
                    background-color: $green-line;
                    color: #fff;
                }
            }
        }

        &:last-child{
            @include borderRadius( 0 16px 16px 0);
        }

        &.popular{
            background-color: #32c3eb;
            @include borderRadius(16px);
            margin-top: -25px;
            margin-bottom: -25px;
            padding-top: 0;
            color: #fff;
            @media (max-width: 991px) {
                margin: 30px 0;
            }

            ul{
                li{

                    i{color: #fff;}
                    
                    @media (max-width: 991px) {    
                        div{
                            i{
                                color: #000831;
                            }
                        }
                    }
                    &:not(:last-child){
                        border-bottom: 1px dashed #fff;
                    }
                }
            }

            .percentageBlock {
                background: rgba(0, 0, 0, 0.5);
            }

            .priceBlock{
        
    
                .btn {
                    border: 1px solid $white;
                    color: $white;
    
                    &:hover{
                        background-color: $white;
                        color: $dark-blue;
                    }
                }
            }
        }

        .popular-badge {
            background: rgb(255, 171, 0);
            padding: 4px 16px;
            border-radius: 0px 0px 20px 20px;
            color: rgb(34, 34, 34);
            margin-bottom: 16px;
            @media (max-width: 991px) {
                margin-left: auto;
                margin-right: 30px;
            }
        }

        
    }
}


.postPropertyWrap *{
    font-family: "Abel", serif;
}

.postPropertyWrap {
    background-color: $white;
	padding: 30px;
	border: 1px solid #ccf4ff;
    @include borderRadius(10px);
	box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 24px 0px;

    @media (max-width: 767px) {
        padding: 20px;
    }

    .postPropertyStatus{
        max-width: 300px;
        border: 1px solid #bef1ff;
        padding: 20px;
        border-radius: 5px;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, rgba(201, 243, 255, 0.8) 100%);
        text-align: left;
        height: 100%;

        @media (max-width: 767px) {
            max-width: 100%;
        }

        @include displayFlex;
        flex-direction: column;
        justify-content: space-between;

        .postPropertyStatusDetail{
            @include displayFlex;
            flex-direction: column;
        }

        .btn-back{
            font-size: 16px;
            margin-bottom: 15px;
            color: #000;
            text-decoration: none;

            i{
                display: inline-block;
                @include transition(all, 0.3s, ease-in-out);
                @include translateX(0px);
      
            }

            &:hover{
                color: $dark-blue;

                i{
                   @include translateX(-5px);
                }
            }
        }

        h4{
            font-weight: 600;
            margin: 0;
        }

        p{
            margin: 0 0 5px;
            font-size: 18px;
            color: #000000;
            text-align: left;
        }

        .progress {
            width: 100%;
            height: 10px;
            background: #fff;

            .progress-bar{
                background-color: $green-line;
            }
        }

        ul{
            padding: 0;
            margin: 20px 0 0;
            list-style: none;

            li{
                @include displayFlex;
                position: relative;

                h5{margin: 0 0 4px;font-weight: 600;cursor: pointer;}

                i{
                    width: 30px;
                    height: 30px;
                    background-color: #0f9ab53b;
                    @include borderRadius(50%);
                    margin-right: 10px;
                    @include displayFlex;
                    justify-content: center;
                    align-items: center;
                }

                span{
                    padding: 3px 15px;
                    font-size: 14px;
                    border-radius: 25px;
                    font-weight: 600;
                    border: 1px solid lightgray;
                    background: transparent;
                    color: #868686;
                }

                &:not(:last-child){
                    padding-bottom: 15px;

                    &::before{
                        content: '';
                        position: absolute;
                        left: 14px;
                        top: 33px;
                        background: #bce6ee;
                        width: 2px;
                        height: calc(100% - 36px);
                    }
                }

                &.inProgress{
                    i{
                        background-color: #2039b4;
                        border: 2px solid #2039b4;
                        box-shadow: 0px 0px 0px 2px #fff inset;
                    }

                    span{
                        background-color: #aff3ff;
                        color: #207f91;
                        border: 1px solid #aff3ff;
                    }

                    &::before{
                        background: #2039b4;             
                    }
                }

                &.completed{
                    i{
                        background-color: $green-line;
                        border: 2px solid $green-line;
                        color: #fff;
                        font-size: 26px;

                        &::before {
                            content: '\F26E';
                            display: inline-block;
                            font-family: bootstrap-icons !important;
                            font-style: normal;
                            font-weight: normal !important;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            vertical-align: -.125em;
                        }
                    }

                    span{
                        background-color: #d4ffca;
                        color: #389721;
                        border: 1px solid #d4ffca;
                    }

                    &::before{
                        background: $green-line;             
                    }
                }
            }
        }

        .quickpost {
            background: #e6f8ff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 40px;
            font-size: 18px;

            a{
                color: $green-line;
                font-weight: 500;

                &:hover{
                    text-decoration: none;
                    color: $dark-blue;
                }
            }
        }
    }

    .postPropertyForm {
        padding: 50px;
        border: 1px solid #ddd;
        border-radius: 7px;
        height: 100%;

        @media (max-width: 767px) {
            padding: 20px;
        }

        .title-link{
            text-decoration: none;
            color: #000;
            @include transition(all, 0.3s, ease-in-out);

            i{
                display: inline-block;
                @include transition(all, 0.3s, ease-in-out);
                @include translateX(0px);
      
            }

            &:hover{
                color: $dark-blue;

                i{
                   @include translateX(-5px);
                }
            }
        }

        .form-label {
            font-size: 20px;
        }
    
        
        
        .custom-check{            
    
            .form-check-label{
                font-size: 18px;
                margin-left: 5px;
                cursor: pointer;
            }
    
            .form-check-input{
                width: 20px;
                height: 20px;
                @include borderRadius(4px);
    
                &:checked {
                    background-color: $green-line;
                    border-color: $green-line;
                }
            }   
            
        }
    
        .form-control{
            @include borderRadius(0);
            border-width: 0 0 1px;
            font-size: 18px;
    
            &.form-search {
                background-image: url(../images/search.svg);
                background-size: 14px;
                background-repeat: no-repeat;
                background-position: left 0px center;
                padding-left: 34px;
               
            }

            &.form-date {background: #fff url(../images/calender.svg) no-repeat right 10px center;padding-right: 40px;}
            
            &.form-rupee {background: #fff url(../images/currency-rupee.svg) no-repeat left 10px center;padding-left: 40px;}

            &:focus, &:focus-visible{
                outline: none;
            }
        }

        i[class*="fa"]{
            font-family: "Font Awesome 6 Free";
        }

        
        .highlight {
            margin-top: 15px;
            border-radius: 64px;
            background: #fff6e3;
            border: 1px solid #f8b018;
            display: flex;
            align-items: center;
            padding: 11px 20px;
            font-size: 18px;
            line-height: 22px;

            i {
                font-size: 35px;
                margin-right: 15px;
                color: #f8b018;
            }
        }

        p{text-align: inherit;}

        

        .uploadwrap {
            border: 1px dashed #666;
            border-radius: 5px;
            @include displayFlex;
            align-items: center;
            justify-content: center;
            height: 80px;
            position: relative;

            input{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                opacity: 0;
                z-index: 1;
            }
            

            i{font-size: 30px;}

            span{
                font-size: 18px;
                font-weight: 600;
            }
        }
    
    }

    .btn-big-green{
        width: 100%;
        background-color: $green-line;
        height: 60px;
        font-size: 20px;
        color: #fff;
        @include transition(all, 0.3s, ease-in-out);

        &:hover{
            background-color: $primaryGreen-10;
        }
    }
}


.rectBox{
    padding: 0;

    .form-check-label{
        border: 1px solid #dbdbdb;
        padding: 0 30px;
        @include borderRadius(8px);
        height: 60px;
        font-size: 18px;
        color: #333;
        @include displayFlex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include transition(all, 0.3s, ease-in-out);

        
    }

    .form-check-input{
        display: none;

        &:checked + .form-check-label{
            background-color: #A4F491;
            border-color: #8ed97c;
            color: #406f08;
        }
    }   

    &.icon-block {

        .form-check-label{
            @include displayFlex;
            flex-direction: column;
            padding: 15px;
            width: 150px;
            text-align: center;
            line-height: 21px;
            height: 100%;

            &.w-135{
                width: 135px;
            }
        i {
            height: 50px;
            margin-bottom: 10px;
            font-size: 30px;
            @include displayFlex;
            align-items: end;
            
            img{
                height: 100%;
            }
          }

          span{
            @include flex-1;
          }
        }
        
    }
    
}

.quill {
    .ql-toolbar{
        @include borderRadius(6px 6px 0 0);
    }
    .ql-container{
        min-height: 200px;
        @include borderRadius(0 0 6px 6px);
        @include displayFlex;
        flex-direction: column;

        .ql-editor{
            @include flex-1;
            p{
                text-align: left;
            }
        }
    }
}