@import 'variable.scss'; 

@mixin flex-1() {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@mixin flex-1() {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
@mixin flexNo($flexNo) {
    -webkit-box-flex: $flexNo;
    -moz-box-flex: $flexNo;
    -webkit-flex: $flexNo;
    -ms-flex: $flexNo;
    flex: $flexNo;
}

@mixin displayFlex() {
    display: -webkit-box; 
    display: -moz-box; 
    display: -ms-flexbox; 
    display: -webkit-flex; 
    display: flex;
}

@mixin flexWrap() {
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;	
}

@mixin threeColorGradient($firstColor: #f3f9ff 0%, $secondColor:#f7fbff 44%, $thirdColor: #f0f7ff 100%) {
    background: $firstColor;
    background: -moz-linear-gradient(top,  $firstColor , $secondColor, $thirdColor); 
    background: -webkit-linear-gradient(top,  $firstColor, $secondColor, $thirdColor); 
    background: linear-gradient(to bottom,  $firstColor, $secondColor, $thirdColor); 
}

@mixin twoColorGradient($firstColor: #f3f9ff 0%, $secondColor:#f7fbff 44%) {
    background: $firstColor;
    background: -moz-linear-gradient(top,  $firstColor , $secondColor); 
    background: -webkit-linear-gradient(top,  $firstColor, $secondColor); 
    background: linear-gradient(to bottom,  $firstColor, $secondColor); 
}

@mixin formField() {    
    max-width: 100%;
    height: 50px;
    border: 1px solid $white;     
    @include borderRadius(4px);
}

@mixin transition($what: all, $time:30s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition:    $what $time $how;
    -ms-transition:     $what $time $how;
    -o-transition:      $what $time $how;
    transition:         $what $time $how;
}

@mixin borderRadius($borderRadius) {
    border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    -moz-border-radius: $borderRadius;
    -ms-border-radius: $borderRadius;
    -o-border-radius: $borderRadius;
}

@mixin translateX($translateX) {
    transform: translateX($translateX);
    -webkit-transform: translateX($translateX);
    -moz-transform: translateX($translateX);
    -ms-transform: translateX($translateX);
    -o-transform: translateX($translateX);
}

@mixin translateY($translateY) {
    transform: translateY($translateY);
    -webkit-transform: translateY($translateY);
    -moz-transform: translateY($translateY);
    -ms-transform: translateY($translateY);
    -o-transform: translateY($translateY);
}

@mixin rotate($rotate) {
    transform: rotate($rotate);
    -webkit-transform: rotate($rotate);
    -moz-transform: rotate($rotate);
    -ms-transform: rotate($rotate);
    -o-transform: rotate($rotate);
}

@mixin translateYrotate($translateY: 10px, $rotate:30deg) {
    transform: translateY($translateY) rotate($rotate);
    -webkit-transform: translateY($translateY) rotate($rotate);
    -moz-transform: translateY($translateY) rotate($rotate);
    -ms-transform: translateY($translateY) rotate($rotate);
    -o-transform: translateY($translateY) rotate($rotate);
}

@mixin scale($scale) {
    transform: scale($scale);
    -webkit-transform: scale($scale);
    -moz-transform: scale($scale);
    -ms-transform: scale($scale);
    -o-transform: scale($scale);
}

@mixin scaleX($scaleX) {
    transform: scaleX($scale);
    -webkit-transform: scaleX($scaleX);
    -moz-transform: scaleX($scaleX);
    -ms-transform: scaleX($scaleX);
    -o-transform: scaleX($scaleX);
}
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacityIE: $opacity * 100;
    filter: alpha(opacity=$opacityIE);
}

@mixin animation($animation...) {
    -webkit-animation: $animation;
    animation: $animation;
}