@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap');

*{padding:0;margin:0;box-sizing: border-box;font-family: 'Jost', sans-serif;}
a:hover {text-decoration: none;}
:focus-visible{outline:none}
:focus{outline:none;box-shadow: none !important;}
.font-8{font-size:8px !important}
.font-9{font-size:9px !important}
.font-10{font-size:10px !important}
.font-11{font-size:11px !important}
.font-12{font-size:12px !important}
.font-13{font-size:13px !important}
.font-14{font-size:14px !important}
.font-15{font-size:15px !important}
.font-16{font-size:16px !important}
.font-17{font-size:17px !important}
.font-18{font-size:18px !important}
.font-19{font-size:19px !important}
.font-20{font-size:20px !important}
.font-21{font-size:21px !important}
.font-22{font-size:22px !important}
.font-23{font-size:23px !important}
.font-24{font-size:24px !important}
.font-25{font-size:25px !important}
.font-26{font-size:26px !important}
.font-27{font-size:27px !important}
.font-28{font-size:28px !important}
.font-29{font-size:29px !important}
.font-30{font-size:30px !important}
.font-31{font-size:31px !important}
.font-32{font-size:32px !important}
.font-600{font-weight:600 !important}
.font-500{font-weight:500 !important}
.font-400{font-weight:400 !important}
.font-300{font-weight:300 !important}
.font-100{font-weight:100 !important}
.text-purple{color:#51499C}
.text-burgandi{color:#D42941}
.text-dark-blue{color:#0B3454}
.text-blue-d6{color:#147AD6 !important}
.text-black{color:#000}
.text-gray-41 {color: #414141}
.text-gray-5f {color:#5F5F5F}
.text-gray-84 {color: #848484}
.text-gray-64 {color: #646464}
.text-gray-53 {color: #535353}
.text-gray-4b {color: #4B4B4B}
.text-gray-e1 {color: #e1e1e1;}
.text-gray-ce {color: #CED6DD}
.text-gray-6d {color: #6D6D6D}
.text-gray-9a {color: #0000009A}
.text-gray-88 {color: #848688}
.text-green {color: #5cae48;}



.min-h-95{min-height: 95px !important;}
.py-100{padding-top: 100px;padding-bottom: 100px;}
.py-80{padding-top: 80px;padding-bottom: 80px;}
.z-1{z-index: 1;}
.z-2{z-index: 2;}
.z-3{z-index: 3;}
.z-4{z-index: 4;}
.z-5{z-index: 5;}
.z-6{z-index: 6;}
.z-7{z-index: 7;}
.z-8{z-index: 8;}
.z-9{z-index: 9;}

body {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;justify-content: space-between;min-height: 100vh;}
header {box-shadow: 0px 3px 5px #00000014;position: sticky;top: 0;z-index: 999;background: #fff;}
.headerWrap {max-width: 1600px;width: 96%;margin: auto;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;justify-content: space-between;align-items: center;padding: 0;min-height: 100px;}
.page-wrap {-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;background: #EFEFEF;}
.rightSideSection {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;white-space: nowrap;}

.searchHeader {position: absolute;left: 25%;width: 0;background: #fff;z-index: 1;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;display: flex !important;align-items:center;height: 100%;overflow: hidden;}
.searchHeader form {width: 100%;}
.searchHeader.show {width: 55%;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;}
.searchHeader.show + #navbarSupportedContent{opacity:0}
.searchtoggle {width: 19px;margin-right: 19px;}
.searchtoggle .search-btn {display: none;}
.searchHeader .form-control {border: 0;width: 100%;font-size: 24px;font-weight: 500;padding: 0 0 0 5%;line-height: 40px;border-left: 2px solid #5cae48;border-radius: 0;}
.searchHeader .form-control::placeholder{color:#000}
.searchHeader .form-control:focus {box-shadow: none;}
.searchHeader .btn {width: 19px;height: 19px;background: url(../images/search_icon.svg);font-size: 0;border: 0;margin: auto;padding: 0;}
.searchHeader.show ~ .mobile-flex .searchtoggle > .cursorpointer {display: none;}
.searchHeader.show ~ .mobile-flex .searchtoggle .search-btn {display: block;}
.header_search {height: 40px;width: 19px;position: relative;}
.header_search #masthead-search-toggle {appearance: none;width: 19px;height: 19px;background: url(../images/search_icon.svg);cursor: pointer;}
.header_search form {position: absolute;width: 0;overflow: hidden;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;}
.header_search #masthead-search-toggle + form .btn {margin: auto 0;width: 21px;height: 19px;border: 0;font-size: 0;background: url(../images/search_icon.svg);border-radius: 0;padding: 0;}
.header_search #masthead-search-toggle:checked + form {width: 230px;right: 0;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;border-bottom: 1px solid #111;background: #fff;}
.header_search form .form-control {border: 0;}
.header_search form .form-control:focus {box-shadow: none;}
.header_search #masthead-search-toggle:checked {transform: translateX(36px);opacity: 0;}
.cursorpointer {cursor: pointer;}
.rightSideSection .searchtoggle {transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.searchHeader.show + .rightSideSection .searchtoggle {opacity: 0;z-index: -1;}

.btn-green { background: #5CAE48 ; border-radius: 4px; color: #fff; font-size: 16px;font-weight: 500; padding: 0 28px; line-height: 40px; transition: all 0.3s ease-in-out; border: 0; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out;display: inline-flex;align-items: center;text-decoration: none;}
.btn-green:hover {background: #0e3453;color: #fff;}
.mainbanner {position: relative;background: url(../images/main-banner.png) no-repeat center / cover;min-height: 300px;display: flex;align-items: center;justify-content: center;position: relative;}
.mainbanner .caption {letter-spacing: 17.82px;color: #FFFFFF;font-size: 60px;line-height: 65px;text-align: center;font-weight: 500;}

.mainbanner.thinkBanner {background: #EDEFF0 url(../images/blug_bg.png) no-repeat center 0 / auto 100%;min-height: 800px;}
.mainbanner.thinkBanner .caption {font-size: 96px;line-height: 96px;font-weight: 800;letter-spacing: 0;display: flex;flex-direction: column;align-items: center;}
.mainbanner.thinkBanner .caption .sub-caption {font-size: 30px;font-weight: 500;line-height: 43px;margin-top: 30px;margin-bottom: 20px;}
.mainbanner.thinkBanner .caption .date {font-size: 18px;font-weight: 500;line-height: 30px;opacity: 0.7;}
.mainbanner.thinkBanner .scrolldown b {background: url(../images/mouse-dark.svg) 0 0 / 100% auto;height: 45px;}

.parter-schools h2 {text-align: center;margin-bottom: 50px;font-size: 50px;font-weight: bold;line-height: 73px;}
.parter-schools h2 span {color: #26A9E1;}

.blue_curve {background: #0D3453 ;border-radius: 0px 20px 20px 0px;margin-left: calc(var(--bs-gutter-x) * -.5);width: 82.2%;display: flex;justify-content: end;}
.blue_curve .curve-img {margin: 13px -21% -12px 0;display: inline-block;position: relative;}
.blue_curve.right {border-radius: 20px 0px 0px 20px;margin-left: auto;margin-right: calc(var(--bs-gutter-x) * -.5);justify-content: start;background: #0D3453 url(../images/group-40770.png) no-repeat 0 0 / 100%;}
.blue_curve.right .curve-img {margin: 13px 0 -12px -29%;}
.blue_curve_detail {max-width: 445px;margin: 0 50px;}
.blue_curve_detail h3 {font-size: 36px;line-height: 37px;font-weight: 600;color: #0D3453;margin-bottom: 15px;}
.blue_curve_detail p {font-size: 18px;line-height: 28px;margin-bottom: 30px;}
.blue_curve_detail .sub-header {margin: 0 0 10px 50px;font-size: 32px;line-height: 36px;font-weight: bold;position: relative;}
.blue_curve_detail .sub-header:before {content: '';position: absolute;width: 80px;height: 125px;background: url(../images/blue_arrow.svg);left: -95px;top: -77px;}
.btn-book {background: #5CAE48 0% 0% no-repeat padding-box;border-radius: 4px;margin: 10px 0 0 50px;display: inline-flex;width: 247px;height: 50px;color: #fff;text-decoration: none;font-weight: 500;font-size: 18px;}
.blue_curve_detail .btn-download-green {margin: 15px 0 0 50px;max-width: 220px;width: 100%;justify-content: center;}
.mb-150{margin-bottom: 150px;}
.highlightSection .page-title h1.title {text-align: center;font-size: 50px;font-weight: 800;line-height: 73px;}

.img-block-wrap > div {-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;}
.img-block {position: relative;}
.img-block-wrap {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;}
.left-pane {margin-right: 3%;}
.right-pane > div:not(:last-child) {margin-bottom: 7%;}
.img-block-wrap .imglink {overflow: hidden;display: inline-block;border-radius: 12px;vertical-align: top;transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.img-block-wrap .imglink:hover {box-shadow: 0 0 40px 1px #ccc;}
.img-block-wrap .imglink img{ transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.img-block-wrap .imglink:hover img { transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); }

.impact-created {background: #26A9E1;padding-top: 80px;}
.impact-created h2 {color: #fff;text-align: center;font-size: 50px;font-weight: bold;line-height: 73px;margin-bottom: 40px;}
.impact-created-wrap ul {padding: 0;margin: 0;display: flex;flex-wrap: wrap;}
.impact-created-wrap ul li {display: flex;flex-direction: column;justify-content: center;min-height: 238px;width: 30.75%;background: #fff;border-radius: 20px 20px 0px 0px;align-items: center;padding: 39px;}
.impact-created-wrap ul li:not(:last-child) {margin-right: 3.8%;}
.impact-created-wrap ul li b {font-size: 64px;font-weight: bold;}
.impact-created-wrap ul li b span {margin-left: 5px;font-size: 48px;}
.impact-created-wrap ul li p {font-size: 20px;font-weight: 500;line-height: 24px;text-align: center;margin: 0;}

.formSection {padding-top: 100px;position: relative;}
.formSection h4.form-tite {font-weight: 600;}
.formSection:before {position: absolute;content: '';left: 0;top: 0;right: 0;background: #fff;height: 72%;}
.body-form-wrap {background: #FFFFFF url(../images/mask-group-18962.svg) no-repeat 0 0;box-shadow: 0px 4px 5px #00000017;border-radius: 4px;max-width: 1140px;margin: auto;text-align: center;margin-bottom: 0px;width: 100%;border: 1px solid #DBDBDB;display: inline-block;}
h4.form-tite {text-align: center;max-width: 575px;margin: auto auto 30px;font-size: 30px;line-height: 35px;width: 90%;}
.body-form {max-width: 720px;margin: 60px auto;background: no-repeat;box-shadow: none;color: #000;display: inline-block;width: 90%;}
.body-form .form-control, .body-form .form-select { border: 1px solid #BACFDE; height: 45px; }
.body-form .btn-submit {width: 150px;height: 50px;background: #5CAE48;color: #fff;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.body-form .btn-submit:hover {background: #0e3453;color: #fff;}

.recentSuccessStories-bucket {display: flex;background: #fff;box-shadow: 0px 25px 100px #00000014;padding: 30px;position: relative;}
.recentSuccessStories-bucket .success-img {border-radius: 6px;overflow: hidden;width: 40.2%;margin-right: 30px;}
.recentSuccessStories-bucket .success-img img { width: 100%; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.recentSuccessStories-bucket:hover .success-img img { transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); }
.success-detail {flex: 1;}
.recentSuccessStories-bucket .success-detail h4 {font-size: 20px;line-height: 28px;font-weight: 600;}
.recentSuccessStories-bucket .success-detail .date {font-size: 14px;color: #5C5C5C;font-weight: 500;}
.recentSuccessStories-bucket .recentLink {position: absolute;left: 0;right: 0;top: 0;bottom: 0;margin: auto;}

.page-content {margin: 40px auto 45px;max-width: 990px;width: 96%;}
/* .footer-fix {position: fixed;left: 0;right: 0;bottom: 0;background: #fff;padding: 15px;text-align: center;box-shadow: 0 -1px 3px 2px #cecece;display: none;z-index: 9;} */
.footer-fix {position: fixed;bottom:0px;width: 100%;height: auto;background: #fff;box-shadow: 0px -7px 6px #00000029;padding: 10px;display: none;text-align: center;z-index: 9;}
.footer-fix .booksessionEnroll {margin: 5px 0 0;}
.footer-fix.smart-scroll.scrolled-down {transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.footer-fix.smart-scroll.scrolled-up {bottom: 0;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}

.categories-tab {overflow: auto;}
.categories-tab .nav-tabs .nav-link { background: #fff; box-shadow: 0px 12px 20px #0000000A; border-radius: 30px; padding: 0 31px; border: 0; line-height: 45px; color: #0D3453; transition: all 0.3s ease-in-out ; -webkit-transition: all 0.3s ease-in-out ; -moz-transition: all 0.3s ease-in-out ; -ms-transition: all 0.3s ease-in-out ; -o-transition: all 0.3s ease-in-out ; }
.categories-tab .nav-tabs .nav-link.active, .categories-tab .nav-tabs .nav-link:hover { background: #0D3453;color: #fff}
.categories-tab .nav {padding: 0;border: 0;margin-bottom: 40px;justify-content: center;}
.categories-tab .nav .nav-item:not(:last-child) {margin-right: 34px;}

.main-blog {border: 1px solid #DBDBDB;border-radius: 4px;position: relative;overflow: hidden;}
.main-blog .blog-detail {padding: 0;}
.main-blog img { transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.main-blog:hover img { transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); }
.main-blog .blog-detail .category {background: #0000008C ;border: 1px solid #FFFFFF;border-radius: 15px;position: absolute;z-index: 1;left: 30px;top: 30px;color: #fff;padding: 0 15px;height: 30px;line-height: 28px;font-size: 12px;font-weight: 500;}
.main-blog .blog-detail .blog-title {position: absolute;bottom: 30px;left: 30px;font-size: 30px;line-height: 35px;font-weight: 600;color: #fff;max-width: 510px;}
.main-blog .blog-detail .blog-title span.date {font-size: 14px;font-weight: 600;display: block;opacity: 0.8;line-height: 30px;}
.blog-block {background: #FFFFFF ;box-shadow: 0px 15px 30px #00000014;border-radius: 4px;overflow: hidden;height: 100%;position: relative;}
.blog-img {vertical-align: top;overflow: hidden;}
.blog-block img { transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.blog-block:hover img { transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); }
.blog-detail {padding: 25px 28px;}
.blog-detail .blog-title .date {opacity: 0.7;font-size: 14px;display: block;font-weight: 600;line-height: 20px;}
.blog-detail .category {background: #FFFFFF ;border: 1px solid #D52941;border-radius: 15px;padding: 0 15px;height: 30px;display: inline-block;font-size: 12px;line-height: 30px;margin-bottom: 15px;font-weight: 500;}
.blog-detail .category.red{color: #D52941;border-color: #D52941;}
.blog-detail .category.blue{color: #26A9E1;border-color: #26A9E1;}
.blog-detail .category.yellow{color: #FBB217;border-color: #FBB217;}
.blog-detail .blog-title {font-size: 20px;line-height: 30px;font-weight: 600;}   
.blogLink {position: absolute;left: 0;right: 0;top: 0;bottom: 0;margin: auto;}
.loader {background: url(../images/loader.svg) no-repeat center / 330%;width: 40px;height: 40px;margin: auto;}

.mainbanner.realGullyBoyBanner {min-height: 990px;background: url(../images/realgullyboy-banner.png) no-repeat center / cover;}
.mainbanner.realGullyBoyBanner .caption {font-weight: 800;font-size: 80px;line-height: 65px;letter-spacing: 0;position: relative;}
.mainbanner.realGullyBoyBanner .caption .sub-caption {display: block;font-size: 30px;line-height:80px;font-weight: 400;}
.mainbanner.realGullyBoyBanner .caption .date {line-height: 18px;font-size: 18px;font-weight: 500;opacity: 0.8;vertical-align: top;margin-top: 0;}
.mainbanner.realGullyBoyBanner.darkshade::before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	background: -moz-linear-gradient(top, rgba(0,0,0,0.83) 0%, rgba(0,0,0,0.17) 80%, rgba(255,255,255,0) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0.83) 0%,rgba(0,0,0,0.17) 80%,rgba(255,255,255,0) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0.83) 0%,rgba(0,0,0,0.17) 80%,rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4000000', endColorstr='#00ffffff',GradientType=0 );
}

.scrolldown {position: absolute;bottom: 30px;left: 0;right: 0;margin: auto;width: 117px;color: #ffffff;}
.scrolldown b {width: 33px;height: 48px;font-size: 0;background: url(../images/mouse.svg) no-repeat 0 0 / 100%;}
.float_animate { transform: translatey(0px); animation: float 4s ease-in-out infinite; -webkit-transform: translatey(0px); -moz-transform: translatey(0px); -ms-transform: translatey(0px); -o-transform: translatey(0px); }

/* video popup */
.vdoplaybtn { cursor: pointer;}
.video_pop, #video_pop { padding: 0 !important; overflow-y: hidden !important;}
/* #video_pop .modal-body, .video_pop .modal-body { padding: 0; height: 315px !important; } */
/* #video_pop .modal-dialog, .video_pop .modal-dialog {max-width: 562px !important;margin: 0 auto !important;height: 315px !important;overflow: hidden;} */
/* video popup end */

#video_pop button.btn-close {
position: absolute;top: 10px;right: 10px;background-color: #fff !important;opacity: 1;z-index: 999;background-size: 40%;width: 30px;height: 30px;border-radius: 50%;padding: 0;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.video-btn {position: relative;}
.video-btn .icon-play {width: 75px;height: 53px;background: url(../images/youtube.svg);position: absolute;left: 0;right: 0;bottom: 0;top: 0;margin: auto;}

@keyframes float {
0% {transform: translatey(0px);-webkit-transform: translatey(0px);-moz-transform: translatey(0px);-ms-transform: translatey(0px);-o-transform: translatey(0px);}
50% {transform: translatey(-10px);-webkit-transform: translatey(-10px);-moz-transform: translatey(-10px);-ms-transform: translatey(-10px);-o-transform: translatey(-10px);}
100% {transform: translatey(0px);-webkit-transform: translatey(0px);-moz-transform: translatey(0px);-ms-transform: translatey(0px);-o-transform: translatey(0px);}
}

.page-title h1.title {text-align: center;font-size: 50px;font-weight: 600;line-height: 37px;}
.page-title h2 {font-size: 32px;line-height: 46px;font-weight: bold;max-width: 450px;}
.page-title p.sub-tite{margin:0;text-align:center;font-size: 18px;font-weight: 500;}
.blog-detail-page p {font-size: 18px;line-height: 28px;margin-bottom: 20px;text-align: left;}

.leftPaneImg {background: url(../images/left-img.png) no-repeat right bottom / cover;width: 100%;height: 100%;display: inline-block;min-height: 700px;}
.left-dot {padding: 7% 0 0 7%;background: url(../images/dot-graph.png) no-repeat top left / 68% auto;}

.rightpane {padding-left: 60px;}
.rightpane h2 {font-size: 50px;line-height: 54px;font-weight: 600;margin-bottom: 20px;}
.rightpane p {font-size: 18px;line-height: 25px;}
.rightpane ul {margin: 0 0 20px;padding: 0;list-style: none;}
.rightpane ul li {position: relative;font-size: 18px;padding-left: 24px;margin-bottom: 6px;}
.rightpane ul li:before {content: '';position: absolute;left: 0;top: 7px;width: 0;height: 0;border-top: 7px solid transparent;border-bottom: 7px solid transparent;border-left: 10px solid #000;}
.btn-download { background: transparent; border: 2px solid #000000; border-radius: 4px; height: 50px; display: inline-flex; padding: 0 25px; align-items: center; text-decoration: none; font-size: 18px; font-weight: 500; color: #070707; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.btn-download i {background: url(../images/download.svg) no-repeat 0 0 / 100%;width: 19px;height: 19px;margin-right: 9px;}
.btn-download:hover {background: #070707;border-color: #070707;color: #fff;}
.btn-download:hover i {filter: invert(1);-webkit-filter: invert(1);}

.leftPane h2 {position: relative;font-size: 50px;font-weight: 600;line-height: 54px;margin-bottom: 45px;}
.leftPane h2:before {position: absolute;content: '';left: 0;top: 109%;width: 65px;height: 6px;background: #D52941;border-radius: 6px;}
.recent_stories {padding: 0;margin: 0 80px 0 0;list-style: none;}
.recent_stories li {display: flex;align-items: center;border-bottom: 1px solid #DEDEDE;max-width: 480px;margin-bottom: 20px;padding-bottom: 20px;position: relative;}
.recentLink{position: absolute;left: 0;right: 0;top: 0;bottom: 0;margin: auto;}
.recent_stories li .recent_stories_thumb {max-width: 200px;width: 100%;overflow: hidden;border-radius: 5px;}

.recent_stories li .recent_stories_thumb img {transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;width: 100%;}
.recent_stories li:hover .recent_stories_thumb img {transform: scale(1.1);-webkit-transform: scale(1.1);-moz-transform: scale(1.1);-ms-transform: scale(1.1);-o-transform: scale(1.1);}
.recent_stories li .recent_stories_detail {padding: 12px 30px;font-size: 20px;font-weight: 500;line-height: 28px;}
.recent_stories li .recent_stories_detail .date {font-size: 14px;display: block;line-height: 32px;font-weight: 600;opacity: 0.7;}

.rightpane h3 {color: #fff;font-size: 30px;line-height: 35px;margin-bottom: 20px;}
.rightSideForm {padding: 70px 60px 43px;background: #FFFFFF url(../images/mask-group-18962.svg) no-repeat 0 0;border-radius: 4px;box-shadow: 0px 4px 5px #00000017;max-width: 463px;}
.rightSideForm.rightGraph {background: #FFF url(../images/right-form-graph.svg) no-repeat top right;box-shadow: 0px 4px 5px #00000017;border: 1px solid #DBDBDB;max-width: 529px;}
.rightSideForm.rightGraph h3 {font-size: 40px;font-weight: bold;color: #000;}
.bg-form {background:#B72134 url(../images/group-40752.svg) no-repeat left 343px bottom;}
.rightpane .form-control, .rightpane .form-select { border: 1px solid #BACFDE; height: 45px;font-weight: 400;font-size: 16px; }
.form-control:focus-visible, .form-select:focus-visible {outline: 1px solid #25506F;}
.form-control:focus, .form-select:focus {outline: 1px solid #25506F;}
.rightpane .btn-submit {width: 150px;height: 50px;background: #5CAE48;color: #fff;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.rightpane .btn-submit:hover {background: #0e3453;color: #fff;}
.btn-cancel {color: #707070;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.btn-cancel:hover {color: #000;}

.bg-yellow{background-color: #FBB217;}
.bg-litebeige {background-color: #FFF9EB;}
.bg-gray-ed {background-color: #EDEFF0;}
.bg-gray-f5 {background-color: #F5F6F8 ;}

.realGullyBoy h1 {font-size: 80px;font-weight: 800;line-height: 96px;}
.realGullyBoy .sub-title {font-size: 30px;line-height: 43px;font-weight: 500;max-width: 387px;width: 100%;margin: 20px 0;}
.realGullyBoy p {line-height: 29px;font-size: 20px;margin-bottom: 30px;}

.btn-white { background: #FFFFFF ; border-radius: 4px; height: 50px; padding: 0 25px; line-height: 50px; font-size: 18px; font-weight: 500; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.btn-white .right-arrow {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3e%3c/svg%3e");width: 22px;height: 22px;display: inline-block;margin-left: 25px;margin-top: 15px;vertical-align: top;transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.btn-white:hover{background-color: #a8760b;color: #fff;}
.btn-white:hover span{ filter:invert(1) brightness(170%) ; -webkit-filter:invert(1) brightness(170%) ; transform: translateX(5px); -webkit-transform: translateX(5px); -moz-transform: translateX(5px); -ms-transform: translateX(5px); -o-transform: translateX(5px); }

.white-element:after {content: '';position: absolute;left: 0;right: 0;bottom: 0;background: #fff;height: 67px;}
.white-element {position: relative;}
footer {background:#0D3453;padding:65px 0 0;width:100%}
footer .container>.footer-row {padding:0 0 25px;margin:0 0 40px;border-bottom:1px solid #071f31;font-size: 14px;}
footer .container>.footer-row:last-child {padding:0;margin:0;border-bottom:0 solid #071f31}
footer a {color:#fff;text-decoration:none}
footer a:hover {color:#fff;text-decoration:underline}
footer .footerlinkcont a {text-decoration:underline;font-weight:600}
footer .footerlinkcont a:hover {text-decoration:none}
.footer-row>p {margin:0 0 25px}
.footer-row.footer-row1>div {margin:0 0 15px;width:100%}
.footer-row.footer-row1>div>div:first-child {width:15%;font-weight:400}
.footer-row.footer-row1>div>div:last-child {width:80%;font-weight:300}
footer .footer-row ul {margin:0;padding:0;list-style:none}
footer .footer-row ul li {margin:0 0 10px;font-weight:400}
footer .footer-row7 .fr7title {margin:0 0 10px;font-weight:400}
footer .footer-row7 .fr7cont {font-weight:300}
.footer_bottom {padding: 50px 0;background: #EDEFF0;margin-top: 50px;color: #000;}
.footer_bottom .container div a {margin:0 12px 0 0}
.footer_bottom .container div a:last-child {margin:0}
.footer_bottom .container div a:hover {opacity:.8}
.footer_video {position:relative;margin:0 auto 10px;width:100%;max-width:318px}
.vdoplaybtn+p {width:100%;max-width:318px;margin:auto;text-align:left}
.footer_video a {position:relative;display:inline-block}
.footer_video a::before {content:"";position:absolute;width:39px;height:39px;border-radius:50%;top:0;left:0;bottom:0;right:0;margin:auto;background:var(--secondary);box-sizing:unset;background-clip:content-box;z-index:1}
.footer_video a::after {content:"";position:absolute;width:0;height:0;top:0;left:48.5%;bottom:0;right:auto;margin:auto;border-left:14px solid #fff;border-top:9px solid transparent;border-bottom:9px solid transparent;z-index:2}

footer > .footer-form {background: #FFFFFF url(../images/mask-group-18962.svg) no-repeat 0 0;box-shadow: 0px 4px 5px #00000017;border-radius: 4px;max-width: 1120px;margin: auto;text-align: center;margin-bottom: 60px;width: 90%;}
footer h4.form-tite {text-align: center;max-width: 414px;margin: auto auto 30px;font-size: 30px;line-height: 35px;width: 90%;}
.footer-form .footer-form {max-width: 720px;margin: 60px auto;background: no-repeat;box-shadow: none;color: #000;display: inline-block;width: 90%;}
.footer-form .form-control, .footer-form .form-select { border: 1px solid #BACFDE; height: 45px; }
.footer-form .btn-submit {width: 150px;height: 50px;background: #5CAE48;color: #fff;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.footer-form .btn-submit:hover {background: #0e3453;color: #fff;}
.btn-cancel {color: #707070;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; }
.btn-cancel:hover {color: #000;}

.modal-content .rightSideForm {max-width: 100%;}
.right-0{right:0}
#downloadBrochure.modal, #downloadForm.modal  {background: #00000075;}
.modal-content .rightSideForm .form-control, .modal-content .rightSideForm .form-select {  border: 1px solid #BACFDE;height: 45px;font-weight: 400;font-size: 16px;font-size: 14px}
.modal-content .rightSideForm  .btn-submit {width: 150px;height: 50px;background: #5CAE48;color: #fff;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.rightSideForm .form-control, .rightSideForm .form-select {  border: 1px solid #BACFDE;height: 45px;font-weight: 400;font-size: 16px;font-size: 14px}
.rightSideForm  .btn-submit {width: 150px;height: 50px;background: #5CAE48;color: #fff;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}

.reg_bg {margin:0 5.5% 50px 0;background: #B72134;}
.red_bg_text {text-align: center;color: #fff;max-width: 404px;width: 100%;margin: 50px auto;font-size: 36px;line-height: 45px;font-weight: bold;}

.mt-min-lg-250 {margin-top: -250px;}

.bg-litebeige:before {content: '';position: absolute;left: 0;bottom: 0;right: 0;height: 80px;background: #fff;}
.beigeSection {z-index: 1;position: relative;padding: 80px 0 50px;}
.beigeSection h2 {text-align: center;font-size: 50px;font-weight: 600;margin: 0 0 20px;}
.beigeSection p {font-size: 24px;text-align: center;margin-bottom: 30px;line-height: 35px;}

 ul.arrow-list {margin: 0 0 20px;padding: 0;list-style: none;}
 ul.arrow-list li {position: relative;font-size: 18px;padding-left: 24px;margin-bottom: 20px;}
 ul.arrow-list li:before {content: '';position: absolute;left: 0;top: 7px;width: 0;height: 0;border-top: 7px solid transparent;border-bottom: 7px solid transparent;border-left: 10px solid #000;}

.btn-download-green {background: #5CAE48;border: 2px solid #5CAE48;border-radius: 4px;height: 50px;display: inline-flex;padding: 0 25px;align-items: center;text-decoration: none;font-size: 18px;font-weight: 500;color: #fff;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.btn-download-green:hover {background: #509F3D;border-color: #509F3D;color: #fff;}
.btn-download-green i {background: url(../images/download.svg) no-repeat 0 0 / 100%;width: 19px;height: 19px;margin-right: 9px;filter: invert(1);-webkit-filter: invert(1);}
.btn-download-green i.icon-calender { background: url(../images/white-calender.svg) no-repeat 0 0 / 100%; width: 18px; height: 18px; margin-right: 9px; filter: invert(0); -webkit-filter: invert(0); }

.recentSuccessStories {padding: 50px 0;}
.recentSuccessStories h2 {font-size: 50px;font-weight: 600;text-align: center;margin: 0 0 40px;}
.recentSuccessStoriesList {max-width: 1140px;width: 1140px;display: flex;flex-wrap: wrap;}

.x-scroll {overflow-x: auto;}


/* scrollbar width */
.x-scroll::-webkit-scrollbar {width: 4px;height: 4px;}

/* scrollbar track */
.x-scroll::-webkit-scrollbar-track {background: #eee;}

/* scrollbar handle */
.x-scroll::-webkit-scrollbar-thumb { border-radius: 1rem; background-color: #333; -webkit-border-radius: 1rem; -moz-border-radius: 1rem; -ms-border-radius: 1rem; -o-border-radius: 1rem; }
.recentSuccessList {width: 350px;}
.recentSuccessList:not(:last-child) {margin-right: 45px;}

.recentSuccessList{ background: #ffff;margin-bottom: 10px; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.listDetail {padding: 30px;background: #FFFFFF;border: 1px solid #E5E5E5;border-top: 0;}
.recentSuccessList:hover {box-shadow: 0px 15px 50px #00000014;}

.listDetail h3 {font-size: 20px;line-height: 29px;margin-bottom: 10px;}
.listDetail p {font-size: 16px;line-height: 23px;margin: 0 0 6px;}
.listDetail .date {font-size: 14px;color: #00000075;margin-bottom: 30px;}
.btn-read {color: #2E2E2E;text-decoration: none;font-size: 16px;font-weight: 500;transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ;}
.btn-read i {background: url(../images/black-arrow.svg) no-repeat 0 0 / 100%;width: 22px;height: 13px;margin-left: 10px;display: inline-block;transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ;}
.recentSuccessList:hover .btn-read {color: #5CAE48;}
.recentSuccessList:hover .btn-read i {background: url(../images/green-arrow.svg) no-repeat 0 0 / 100%;margin-left: 5px;}

.recentSuccessList:hover .btn-read:hover {color: #0D3453;}
.recentSuccessList:hover .btn-read:hover i {background: url(../images/black-arrow.svg) no-repeat 0 0 / 100%;margin-left: 10px;}

.btn-viewmore {background: #5CAE48;width: 270px;height: 50px;border-radius: 4px;color: #fff;display: flex;align-items: center;text-decoration: none;font-size: 18px;font-weight: 500;transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ;padding: 0 65px;}
.btn-viewmore i { background: url(../images/black-arrow.svg) no-repeat 0 0 / 100%; width: 22px; height: 13px; margin-left: 20px; display: inline-block; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; filter: invert(1) brightness(200%); -webkit-filter: invert(1) brightness(200%);}
.btn-viewmore:hover{color: #fff;background-color:#459930  ;}
.btn-viewmore:hover i{margin-left:25px}


/* fold css*/

.fold-wrap ul.listed-points {margin: 0 0 20px;padding: 0;list-style: none;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;}
.fold-wrap ul.listed-points li {position: relative;font-size: 18px;padding-left: 24px;margin-bottom: 6px;max-width: 550px;width: 100%;}
.fold-wrap ul.listed-points li:before {content: '';position: absolute;left: 0;top: 7px;width: 0;height: 0;border-top: 7px solid transparent;border-bottom: 7px solid transparent;border-left: 10px solid #000;}

.fold-wrap .fold-section:nth-child(2n) .added-img{background: #CCCDCE;}
.fold-wrap .added-img {max-width: 500px;width: 100%;min-height: 350px;margin: auto;background: #F4F6F9 ;border-radius: 4px;display: flex;flex-direction: column;align-items: center;justify-content: center;position: relative;}
.fold-wrap .fold-section {background: #fff;padding: 0;}
.fold-wrap .fold-section:nth-child(2n){background-color: #F4F6F9;}
.fold-wrap .fold-section-ui {max-width: 1140px;margin: auto;padding: 70px 0;width: 90%;}
.fold-wrap .added-img .img-options {position: absolute;bottom: 20px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;}
.fold-wrap .added-img .img-options .img-upload {margin-right: 10px;background: #fff;padding: 0 13px;display: flex;align-items: center;border-radius: 3px;}
.fold-wrap .added-img .img-options .img-upload .btn-browse {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;align-items: center;}
.fold-wrap .added-img .img-options .img-upload .btn-browse i {display: flex;}
.fold-wrap .img-alignment .form-select {border: 0;height: 40px;font-size: 14px;font-weight: 500;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='0' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e");background-size: 15px;}
.fold-wrap .added-img .img-options .img-upload .upload-img {margin-bottom: 0;}
.fold-wrap .added-video {max-width: 500px;width: 100%;min-height: 350px;margin: auto;background: #F4F6F9 ;border-radius: 4px;display: flex;flex-direction: column;align-items: center;justify-content: center;position: relative;}
.fold-wrap .fold-section-ui h1 {font-size: 50px;font-weight: 600;margin-bottom: 26px;}
.fold-wrap .fold-section-ui  p {font-size: 18px;line-height: 25px;margin-bottom: 20px;}

.fold-wrap .input-group.video-link {position: absolute;bottom: 20px;left: 20px;right: 20px;z-index: 1;width: auto;margin: auto;background: #F4FBFE ;border: 1px solid #0B34544D;border-radius: 4px;height: 40px;}
.fold-wrap .videoSection {position: relative;}
.fold-wrap .input-group.video-link .input-group-text {height: 100%;background: #F4FBFE;border: 0;font-size: 14px;font-weight: 500;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;line-height: 10px;}
.fold-wrap .input-group.video-link .form-control {height: 100%;border: none;}
.fold-wrap .input-group.video-link .input-group-text i {margin-right: 10px;}

.template_4 {background-image: url(../images/ribbon-element.png) , url(../images/semi-circle.svg);background-repeat: no-repeat;background-size: auto , 40.1%;background-position: 0 -89px, top -25px right;min-height: 870px;}

.customfooter {background: #f5f6f8;padding: 0;}
.customfooter .footer_bottom {margin: 0;background: none;}
.customfooter .footer_bottom > div {border-top: 1px solid #E8E9EB;padding: 20px 0 0;}

.template_4 .title {font-weight: 800;font-size: 64px;line-height: 80px;color: #0D3453;}
.template_4 p{font-size: 18px;text-align: left;}

@media (max-width:1600px) {
.realGullyBoy h1 {font-size: 70px;font-weight: 800;line-height: 76px;}
.realGullyBoy .sub-title {font-size: 24px;line-height: 32px;}
.realGullyBoy p {line-height: 24px;font-size: 18px;}
}
@media (max-width:1500px) {
.realGullyBoy h1 {font-size: 60px;font-weight: 800;line-height: 66px;}
.realGullyBoy .sub-title {font-size: 22px;line-height: 26px;}
.realGullyBoy p {line-height: 22px;font-size: 16px;}
.template_4 {background-size: 95vh auto , 39.4%;background-position: -210px -84px, top -25px right;}
}
@media (max-width:1400px) {
.rightSideForm.rightGraph{max-width: 450px;}
.blue_curve {align-items: center;height: 100%;}
.blue_curve img {width: 440px;}
.blue_curve.right img {width: 300px;}
}
@media (max-width:1366px) {
.mainbanner.thinkBanner {min-height: 570px;}
.mainbanner.thinkBanner .scrolldown {bottom: 7px;}
.mainbanner.thinkBanner .caption {font-size: 60px;line-height: 66px;}
.mainbanner.thinkBanner .caption .sub-caption {font-size: 24px;line-height: 28px;}
}
@media (max-width:1199px) {
.leftPane {padding: 0 50px;}
.rightpane {padding: 0 50px;}
.rightSideForm {max-width: 100%;}
.recent_stories {padding: 0;margin: 0;list-style: none;width: 100%;}
.template_4 .title {font-size: 50px;line-height: 60px;}
.realGullyBoy h1 {font-size: 60px;font-weight: 800;line-height: 70px;}
}
@media (max-width:1100px) {
.white-element::after {height: 40px;}
}
@media (max-width:991px) {
.footer-row.footer-row1>div>div:first-child, .footer-row.footer-row1>div>div:last-child {    width:100%}
footer .container>.footer-row>div {margin-bottom:20px}
.footer-row.footer-row1>div>div:first-child {margin-bottom:5px}
footer .container>.footer-row {padding:0 0 20px}
footer .container>.footer-row:last-child {padding:0}
.font-30 {font-size:24px}
.font-39 {font-size:30px}
.blog-detail .blog-title br {	display: none;}
.mainbanner.realGullyBoyBanner {min-height: 600px;}
.leftPaneImg {min-height: 400px;}
.rightpane {padding: 0 50px;}
.rightpane h2 {font-size: 40px;line-height: 44px;}
.bg-form {background: #B72134 url(../images/group-40752.svg) no-repeat left 23vw bottom;}
.realGullyBoy h1 {font-size: 50px;font-weight: 800;line-height: 56px;max-width: 330px;}
.reg_bg {margin: 20px 0 50px;background: #B72134;}
.rightSideForm.rightGraph {max-width: 530px;width: 100%;margin: auto;}
.bg-litebeige::before {height: 40%;}
.beigeSectiondetail {max-width: 550px;width: 100%;margin: 30px auto 0;}
.mainbanner.thinkBanner .caption {font-size: 60px;}
.mainbanner.thinkBanner .caption .sub-caption {max-width: 640px;width: 90%;font-size: 24px;line-height: 36px;}
.mainbanner.thinkBanner {min-height: 570px;}
.mainbanner.thinkBanner .scrolldown {bottom: 10px;}
.highlightSection .page-title h1.title {font-size: 40px;line-height: 53px;}
.parter-schools h2 {font-size: 40px;line-height: 43px;}
.blue_curve img {width: 100%;max-width: 620px;}
.blue_curve_detail {margin: auto;}
.blue_curve.right img {width: 380px;}
.blue_curve.right .curve-img {margin: 13px 0 -12px -23%;}
.impact-created-wrap ul li {padding: 20px;}
.impact-created-wrap ul li b {font-size: 40px;}
.impact-created-wrap ul li p {font-size: 16px;}
.template_4 .title {font-size: 40px;line-height: 45px;}
.mb-150 {margin-bottom: 90px;}
}
@media (max-width:900px) {
.realGullyBoy h1 {font-size: 40px;font-weight: 800;line-height: 40px;max-width: 260px;}
.realGullyBoy .sub-title {font-size: 20px;line-height: 26px;}
.realGullyBoy p {line-height: 20px;font-size: 14px;margin-bottom: 20px;}
.white-element::after {height: 10px;}
}
@media screen and (min-width: 768px) and (max-width: 950px) {
.recent_stories li {flex-direction: column;align-items: start;}   
.recent_stories li .recent_stories_detail {padding: 10px 0 0;}
.rightSideForm {max-width: 100%;padding: 70px 30px 43px;}
}

@media (max-width:905px){
.categories-tab ul {width: max-content;}
}

@media (max-width:870px){
.searchHeader.show {width: 350px;}
.searchHeader {left: 26%;}
}

@media (max-width:767px){
.headerWrap {width: 94%;} 
.page-content {width: 94%;} 
.mainbanner .caption {letter-spacing: 0;font-size: 45px;line-height: 45px;}
.rightSideSection .btn.btn-green {display: none;}
.footer-fix{display: block;}
.searchHeader {left: 190px;}
.searchHeader .btn {background: url(../images/search_icon.svg) no-repeat 0 0 / 100%;}
.searchHeader .form-control {font-size: 18px;font-weight: 400;}
.mainbanner.realGullyBoyBanner {min-height: 500px;}
.mainbanner.realGullyBoyBanner .caption {font-weight: 600;font-size: 40px;line-height: 45px;}
.mainbanner.realGullyBoyBanner .caption .sub-caption {font-size: 20px;line-height: 45px;}
.mainbanner {background: url(../images/main-banner.png) no-repeat right 30% center / cover;}
.realGullyBoy h1 {font-size: 10vw;line-height: 10vw;width: 100%;max-width: 520px;}
.realGullyBoy .sub-title {font-size: 24px;line-height: 30px;}
.realGullyBoy p {line-height: 30px;font-size: 18px;margin-bottom: 25px;}
.left-dot {width: 90%;margin: 40px auto 0;}
.white-element::after {height: 40%;}
.fold-wrap .fold-section-ui h1 {font-size: 40px;}
.impact-created-wrap {width: 800px;}
.recentSuccessStories-bucket {align-items: center;}
.mb-150 {margin-bottom: 80px;}
.impact-created h2 {font-size: 40px;line-height: 45px;}
.template_4 {background-size: 99vh auto , 125% auto;background-position: -140px -84px, top -25px right;}
.template_4 .title {color: #fff;}
.template_4 .title ~ p{color: #fff;}
}

@media (max-width:575px){
.headerWrap {width: 92%;padding: 15px 0;}  
.page-content {width: 92%;}   
.searchHeader {width: auto !important;left: -14px;top: 60px;right: 0;height: 41px;box-shadow: 0 0 2px 1px #ccc;opacity: 0;padding-right: 10px;}
.searchHeader.show {opacity: 1;top: 100%;}
.searchHeader .form-control {font-size: 16px;font-weight: 400;}
.main-blog {background: #FFFFFF ;box-shadow: 0px 15px 30px #00000014;border-radius: 4px;overflow: hidden;height: 100%;}
.main-blog .blog-detail {padding: 25px 28px;}
.main-blog .blog-detail .category {position: static;background: #FFFFFF ;border: 1px solid #D52941;border-top-color: rgb(213, 41, 65);border-right-color: rgb(213, 41, 65);border-bottom-color: rgb(213, 41, 65);border-left-color: rgb(213, 41, 65);border-radius: 15px;padding: 0 15px;height: 30px;display: inline-block;font-size: 12px;line-height: 30px;margin-bottom: 15px;font-weight: 500;color: #D52941;}
.main-blog .blog-detail .blog-title {position: static;color: #000;font-size: 20px;line-height: 30px;font-weight: 600;}
.mainbanner.realGullyBoyBanner {min-height: 300px;}
.mainbanner.realGullyBoyBanner .caption {font-weight: 600;font-size: 30px;line-height: 35px;}
.mainbanner.realGullyBoyBanner .caption .sub-caption {font-size: 18px;line-height: 30px;}
.categories-tab .nav .nav-item:not(:last-child) {margin-right: 10px;}
.categories-tab .nav-tabs .nav-link {padding: 0 20px;}
.leftPane {padding: 30px;}
.rightpane {padding: 30px;}
.rightpane h2 {font-size: 32px;line-height: 44px;}
.rightSideForm {max-width: 100%;padding: 70px 30px 43px;}
.recent_stories li {flex-direction: column;align-items: start;}   
.recent_stories li .recent_stories_detail {padding: 10px 0 0;}
.py-80 {padding-top: 20px;padding-bottom: 20px;}
.py-100 {padding-top: 50px;padding-bottom: 50px;}
.leftPane h2 {font-size: 34px;}
.page-title h1.title {text-align: left;font-size: 36px;}
.page-title p.sub-tite {text-align: left;}
.main-blog .blog-detail .blog-title span.date {font-size: 14px;font-weight: 600;display: block;opacity: 0.7;line-height: 30px;color: #000;}
.white-element::after {height: 25%;}
.red_bg_text {max-width: 100%;width: 100%;margin: 40px 0;font-size: 24px;line-height: 34px;font-weight: bold;padding: 0 30px;}
.beigeSection {z-index: 1;position: relative;padding: 40px 0 0px;}
.beigeSection h2 {font-size: 34px;}
.beigeSection p {font-size: 18px;text-align: center;margin-bottom: 0px;line-height: 28px;}
.recentSuccessStories h2 {font-size: 40px;}
.fold-wrap .fold-section-ui {padding: 40px 0;}
.blue_curve_detail {padding-left: 50px;}
.mb-150 {margin-bottom: 60px;}
.impact-created h2 {font-size: 36px;line-height: 40px;}
.mainbanner.thinkBanner .caption {font-size: 40px;line-height: 45px;}
.mainbanner.thinkBanner {min-height: 450px;}
.highlightSection .page-title h1.title {font-size: 24px;line-height: 40px;}
.parter-schools h2 {font-size: 30px;line-height: 43px;}
.blue_curve_detail h3 {font-size: 28px;}
.blue_curve_detail .sub-header {font-size: 24px;line-height: 30px;}
.formSection h4.form-tite {font-size: 24px;}
.blue_curve.right img {	width: 200px;	margin-bottom: 15px;}
.template_4 {background-size: 75vh auto , 100vh auto;}
}